<template>
    <v-container fill-height>
        <v-layout justify-center wrap >
            <v-flex md12>
                <material-card title="Categories"
                               text="">

                    <template :slot="'header_actions'">
                        <v-btn small fab rounded
                               v-tooltip="'New Category'" color="white"
                               @click="setDefaultCategoryOptions('', '')">
                            <v-icon color="primary">
                                mdi-plus
                            </v-icon>
                        </v-btn>
                    </template>

                    <template>
                        <table class="table">
                       <tr>
                           <th>#</th>
                           <th class="text-xs-left">Name</th>
                           <th class="text-xs-left">Children</th>
                       </tr>
                       <tr v-for="category in categories">
                           <td style="vertical-align: top;">
                             {{ category.id }}
                           </td>
                           <td style="vertical-align: top;">
                               <span @click="editCategory(category)">
                                   {{ category.name }}
                               </span>

                               <v-btn rounded small fab text
                                       color="success"
                                       @click="setDefaultCategoryOptions('', category.id)">
                                   <v-icon>
                                       mdi-plus
                                   </v-icon>
                               </v-btn>

                           </td>
                           <td style="vertical-align: top;">
                               <ul class="first_child">
                                   <li v-for="first_child in category.children" >

                                       <span @click="editCategory(first_child)">
                                           {{ first_child.name }}
                                       </span>

                                       <v-btn rounded text small fab
                                               color="success"
                                               @click="setDefaultCategoryOptions('', first_child.id)">
                                           <v-icon>
                                               mdi-plus
                                           </v-icon>
                                       </v-btn>

                                       <ul class="second_child">
                                            <li v-for="second_child in first_child.children">
                                                <span @click="editCategory(second_child)">
                                                    {{ second_child.name }}
                                                </span>

                                                <v-btn rounded text small fab
                                                        color="success"
                                                        @click="setDefaultCategoryOptions('', second_child.id)">
                                                    <v-icon>
                                                        mdi-plus
                                                    </v-icon>
                                                </v-btn>

                                                <ul class="third_child">
                                                    <li v-for="third_child in second_child.children">
                                                        <span @click="editCategory(third_child)">
                                                            {{ third_child.name }}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </li>
                                       </ul>
                                   </li>
                               </ul>
                           </td>
                       </tr>
                   </table>
                    </template>

                </material-card>
            </v-flex>

            <v-flex md12>
                <v-dialog v-if="!!category_dialog" v-model="category_dialog" width="500">
                    <v-card>
                        <v-card-title primary-title class="headline grey lighten-2">
                           {{ category.name }}
                        </v-card-title>

                        <v-card-text>
                            <v-form
                                    ref="form"
                                    v-model="category_form"
                                    onSubmit="return false;">

                                <v-text-field
                                        v-model="category.name"
                                        label="Name"
                                        required=""></v-text-field>

                                <v-text-field
                                        v-model="category.slug"
                                        label="URL"
                                        required=""></v-text-field>

                                <v-select
                                      v-model="category.state_id"
                                      :items="states"
                                      item-value="id"
                                      item-text="name"
                                      label="State"
                                      required=""></v-select>

                                <input v-model="category.id" type="hidden">
                                <input v-model="category.parent_id" type="hidden">
                            </v-form>
                        </v-card-text>


                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary"
                                   @click="saveCategory(category)">
                                Save Category
                            </v-btn>
                        </v-card-actions>

                    </v-card>
                </v-dialog>

                <helper-flash-item :notifcation="notification" :notification_message="notification_message"></helper-flash-item>

            </v-flex>
        </v-layout>
    </v-container>

</template>

<script>
    export default {
        name: "factories",
        data: () => ({
            category_form: undefined,
            notification: false,
            notification_message: '',
            title: "factories",
            categories: [],
            category_dialog:false,
            category: {},
            states: [
                {
                    id:1,
                    name: 'Active'
                },
                {
                    id:0,
                    name: 'Disabled'
                }
            ]
        }),
        created () {
            this.loadCategories();
        },
        methods: {
            setDefaultCategoryOptions(id, parent) {
                this.editCategory({
                    name: '',
                    slug: '',
                    state_id: 1,
                    id:id,
                    parent_id: parent,
                })
            },
            async loadCategories()
            {
                this.categories = await this.$http.get('/admins/categories/')
                                                  .then(response=>response.data);

                this.notification = true;
                this.notification_message = 'Successfully Loaded';

            },
            saveCategory(category) {
                category.parent = category.parent_id;
                category.state = category.state_id;

                if (category.id === '' || !category.id)
                {
                    this.$http.post('/admins/categories/', category)
                        .then((response)=>{
                            this.loadCategories();
                            this.category_dialog = false;
                        })
                        .catch((error)=>{
                            console.log(error);
                        });
                } else {
                    this.$http.patch('/admins/category/' + category.id, category)
                        .then((response)=>{
                            console.log("success");
                            this.category_dialog = false;
                        })
                        .catch((error)=>{
                            console.log(error);
                        });
                }

                console.log(category);
            },
            editCategory(category){
                this.category = category;
                this.category_dialog = true;
            }
        }
    }
</script>

<style scoped>

</style>
